.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(255, 255, 255, 0.95);
  overflow: hidden;
}


.cardModal {
  position: relative;
 display: flex;
 justify-content: center;
 justify-items: center;
  z-index: 100;

}

.modal {
  border-radius: 6px;
  background-color: white;
 position: absolute;
 top:50px;
  height: 700px;
  max-height: 90vh;
  z-index: 100;
  overflow: auto;

 filter: drop-shadow(0 0 0.5rem rgba(0, 2, 0, 0.346));
}

.header {
  background: #ffffff;
  padding: 1rem;
  position: sticky;
  top:0;
}

.header h2 {
  margin: 0;

}

.content {
  padding: 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
